(function($){

	var docReady = function(){
        // search
        $('[data-action="desktop-search"]').on('click', function (e) {
            $('body').toggleClass('desktop-search-open');
        });
        $('[data-action="close-desktop-search"]').on('click', function (e) {
            e.preventDefault();
            $('body').removeClass('desktop-search-open');
        })

        // homepage training tabs
        $trainingTabsContainer = $('.training-tabs');
        if ($trainingTabsContainer.length > 0) {
            var activeTabIndex = $trainingTabsContainer.attr('data-active-tab');
            var trainingTabContentContainer = $('.training-tab__flex-container')
            $tabControls = $trainingTabsContainer.find('[data-action="training-tab-rotate"]');
            $tabControls.on('click', function (e) {
                e.preventDefault();
                $clickedTab = $(e.target);
                if ($clickedTab.prop("tagName") == 'H2') {
                    $clickedTab = $clickedTab.parent();
                }

                var clickedTabIndex = $clickedTab.attr('data-i');
                if (clickedTabIndex == activeTabIndex) {
                    return;
                }

                $tabControls.toggleClass('-active');
                $tabControls.toggleClass('-inactive');

                activeTabIndex == 1
                    ? activeTabIndex = 2
                    : activeTabIndex = 1;

                $trainingTabsContainer.attr('data-active-tab', activeTabIndex)
                trainingTabContentContainer.attr('data-active-tab', activeTabIndex);


            });
        }

        // home page how-it-works slider
        $homeHowItWorksSlider = $('.how-it-works__slider');
        if ($homeHowItWorksSlider.length > 0) {
            $homeHowItWorksSlider.slick({
                mobileFirst: true,
                slidesToShow: 1,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            })
        }

        $homeTestimonialsSlider = $('.testimonials__slider');
        if ($homeTestimonialsSlider.length > 0) {
            $homeTestimonialsSlider.slick({
                mobileFirst: true,
                slidesToShow: 1,
                infinite: true,
                dots: true,
                centerMode: true,
                appendDots: '.testimonials__slider-dots',
                appendArrows: '.testimonials__slider-controls',
                centerPadding: 0,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            })
        }

        $trainingBenefitsSlider = $('.benefits__list');
        if ($trainingBenefitsSlider.length > 0) {
        	$trainingBenefitsSlider.slick({
        		mobileFirst: true,
        		slidesToShow: 1,
        		infinite: true,
        		dots: true,
        		centerMode: true,
        		appendDots: '.benefits__slider-dots',
        		appendArrows: '.benefits__slider-controls',
        		centerPadding: 0,
        		responsive: [
        			{
        				breakpoint: 640, //phablet
        				settings: {
        					slidesToShow: 2
        				}
        			},
        			{
        				breakpoint: 1024, // laptop
        				settings: {
        					slidesToShow: 3
        				}
        			}
        		]
        	});
        }

        var rellax = new Rellax('.rellax-container');

        var $faqs = $('[data-component="faq"]');
        if ($faqs.length > 0) {
            $faqs.find('[data-subcomponent="trigger"]').on('click', function () {
                $faqs.removeClass('open');
                $(this).closest('[data-component="faq"]').addClass('open');
            });
        }
    };
 
    var resourcesSliders = function(){
        if(! $('[data-resources-slick]').length ){
            return;
        }
        var arrowSvg = '<svg class="carousel-arrow" width="34px" height="44px" viewBox="0 0 34 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-408.000000, -1037.000000)" fill-rule="nonzero"><g transform="translate(249.000000, 505.000000)"><g transform="translate(159.000000, 532.000000)"><g transform="translate(16.620000, 22.000000) scale(-1, 1) translate(-16.620000, -22.000000) translate(0.120000, 0.000000)"><g transform="translate(8.843400, 0.000000)"><polygon fill="#034659" class="carousel-arrow__fill-bottom" points="6.83557093 43.2344 23.5824 21.6172 16.7468291 21.6172 0 43.2344"></polygon><polygon fill="#18988B" class="carousel-arrow__fill-top" transform="translate(11.791200, 10.808600) scale(1, -1) translate(-11.791200, -10.808600) " points="6.83557093 21.6172 23.5824 0 16.7468291 0 0 21.6172"></polygon></g><g><polygon fill="#034659" class="carousel-arrow__fill-bottom" points="6.83557093 43.2344 23.5824 21.6172 16.7468291 21.6172 0 43.2344"></polygon><polygon fill="#18988B" class="carousel-arrow__fill-top" transform="translate(11.791200, 10.808600) scale(1, -1) translate(-11.791200, -10.808600) " points="6.83557093 21.6172 23.5824 0 16.7468291 0 0 21.6172"></polygon></g></g></g></g></g></g></svg>';
        $('[data-resources-slick]').each( function(){
            var slickSettings = {
                appendArrows: $(this).siblings('[data-resources-slick-nav]'),
                appendDots: $(this).siblings('[data-resources-slick-nav]'),
                prevArrow: '<button class="prev">' + arrowSvg + '</button',
                nextArrow: '<button class="next">' + arrowSvg + '</button',
                arrows: true,
                autoplay: false,
                dots: true,
            };
            $(this).slick(slickSettings);
        } );
    };

    var appPackagesList = function(){
    	$('[data-action="description-link"]').on('click', function(e){
    		var target = $(this).parents('.package-inner');
    		target.addClass('show-full-description full-description-clickable');
    	});

    	$('[data-action="description-close"]').on('click', function(e){
    		var target = $(this).parents('.package-inner');
    		target.removeClass('show-full-description');
    		setTimeout(function(){
    			target.removeClass('full-description-clickable');
    		}, 500);
    	});

    	// $('[data-action="package-quote"]').on('click', function(e){
    	// 	e.preventDefault();
    	// 	var target = $(this).attr('href');

    	// 	// target.addClass('show-full-description full-description-clickable');
    	// });
    	
    	$('[data-action="package-quote"]').magnificPopup({
    		type: 'inline'
    	})

    };

	$(document).ready(function(){
        docReady();
        resourcesSliders();
        appPackagesList();

	});

})(jQuery);