(function($){

    function AddToCartButton($container, type) {
        this.$container = $container;
        this.type = type;
        if (this.type == 'text') {
        	this.$button = this.$container;
        } else {
        	this.$button = $container.find('.ms-ajax-add-to-cart');
        }

        if (this.type == 'icon') {
            this.$errorContainer = $container
                .closest('.product-preview-actions')
                .find('[data-subcomponent="errors"]');
        } else {
        	this.$errorContainer = $container
        		.closest('product-actions')
        		.find('[data-subcomponent="errors"]');
        }

        var _self = this;
        this.$button.on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            _self.addToCart(_self.getProductInfo());
        });

        this.getProductInfo = function () {
            productInfo = {
                id: null,
                type: null,
                slug: null, 
                sku: null,
                quantity: null,
                variations: null,
                groupedProducts: null,
                productBundles: null,
                onePerUser: null,
            }

            productInfo.variations = null;
            productInfo.groupedProducts = null;
            productInfo.productBundles = null;

            var productOptions = [];

            productInfo.type = this.$button.attr('data-product_type');
            productInfo.id = this.$button.attr('data-product_id');
            productInfo.slug = this.$button.attr('data-product_slug');
            productInfo.sku = this.$button.attr('data-product_sku');
            productInfo.onePerUser = false;
            productOptionSelector = $('.' + productInfo.id + '-' + productInfo.type + '-' + productInfo.slug);
            productQuantityInput = $('.' + productInfo.id + '-' + productInfo.type + '-' + productInfo.slug + '-quantity');
            productInfo.quantity = productQuantityInput.val();
            productInfo.onePerUser = productQuantityInput.attr('data-one-per-user') == 'true'
                ? true
                : false;
            if(productInfo.quantity == '') { 
                productInfo.quantity = 1;
            }

            switch(productInfo.type) {
                case 'simple':
                    break;
                case 'variable':
                    $.each(productOptionSelector, function() {
                        var name = $(this).attr('data-variable_name');
                        var selected = this.options[this.selectedIndex].text;
                        var variation = {
                            name: name,
                            selected: selected,
                        };
                        productOptions.push(variation);
                    });
                    productInfo.variations = productOptions;
                    break;
                case 'grouped':
                    $.each(productOptionSelector, function() {
                        var id = $(this).attr('data-product_id');
                        var quantity = $(this).find('input').val();
                        var childProduct = {
                            id: id,
                            quantity: quantity
                        };
                        productOptions.push(childProduct);
                    });
                    productInfo.groupedProducts = productOptions;
                    break;
                default:
                    break;
            }

            return productInfo;
        };

        this.addToCart = function(productInfo) {
            this.startSpinner()
            this.clearErrors();
            
            var query = $.ajax({
                url: MSWObject.site_url + '/?wc-ajax=add_to_cart',
                method: 'POST',
                data: {
                    product_id: productInfo.id,
                    quantity: productInfo.quantity,
                },
                error: function(textStatus) {
                    _self.handleFailure('Unknown error. Please retry.')
                    _self.stopSpinner();
                },
                success: function(data, status, jqXhr) {
                    _self.handleSuccess(data, productInfo, status, jqXhr);
                },
            });
        };

        this.startSpinner = function() {
            this.$container.addClass('loading');
        };

        this.stopSpinner = function() {
            this.$container.removeClass('loading');
        };

        this.handleSuccess = function (data, productInfo, status, jqXhr){

            if (('error' in data) && data.error == true) {
                this.showError(data.product_url);
                this.stopSpinner();
                return;
            }

            $('#menu-cart .cart-count').replaceWith(data.fragments.menu_cart_count);
            this.updateCartDropdown(productInfo.onePerUser, data.fragments['div.widget_shopping_cart_content']);
        };

        this.updateCartDropdown = function(onePerUser, fragment) {
            $('.cart-dropdown').html($(fragment).find('.mini-cart-wrapper'));
            this.manifestCart();
            this.markAdded(onePerUser);
            this.stopSpinner();
        }

        this.handleFailure = function (){
            console.log('the failure handler');
        };

        this.clearErrors = function() {
            this.$errorContainer.text('');
        };

        this.showError = function(message) {
            this.$errorContainer.html(message);
        }

        this.manifestCart = function() {
            $('body').addClass('cart-open');
        }

        this.replaceCartContents = function(html) {
            $('.site-header .cart-dropdown').html(html);
        }

        this.updateCartCount = function() {
            $('#menu-cart').addClass('show-dot');
        }

        this.markAdded = function(onePerUser) {
            if (! onePerUser) {
                return;
            }

            this.$container.addClass('added');
            if (this.type == 'text') {
            	this.$container.addClass('disabled');
            }
        }
    }

    function QuantityInput($container, $priceMessage) {
        var $container = $container;
        var $priceMessage = $priceMessage;
        var $incrementor = $container.find('[data-subcomponent="cart-quantity-increment"]');
        var $decrementor = $container.find('[data-subcomponent="cart-quantity-decrement"]');
        var $input = $container.find('input');
        var originalQuantity = parseInt($container.attr('data-starting-quantity'));
        var cartItemKey = $container.attr('data-cart-item-key');

        var increment = function() {
            var quantity = parseInt($input.val());

            if (isNaN(quantity)) {
                quantity = 0;
            }

            $input.trigger('change');

            $input.val(quantity + 1);
        }

        var decrement = function() {
            var quantity = parseInt($input.val());

            if (isNaN(quantity)) {
                quantity = 0;
            }

            $input.trigger('change');

            $input.val(Math.max(quantity - 1, 0));
        }

        var maybeShowPriceMessage = function() {
            console.log(originalQuantity);
            console.log($input.val());
            if ($input.val() != originalQuantity) {
                $priceMessage.set(cartItemKey, true);
            } else {
                $priceMessage.set(cartItemKey, false);
            }
        }

        $incrementor.on('click', function(e) {
            e.preventDefault();
            increment()
            maybeShowPriceMessage()
        })

        $decrementor.on('click', function(e) {
            e.preventDefault();
            decrement()
            maybeShowPriceMessage()
        })

    }

    function PriceMessageState() {
        var state = {};
        var $message = $('[data-component="price-message"]');

        var showOrHideMessage = function() {
            if (shouldShowMessage()) {
                $message.addClass('show');
            } else {
                $message.removeClass('show');
            }
        }

        var shouldShowMessage = function() {
            for(var k in state) {
                if (!state[k]) {
                    return false;
                }
            }

            return true;
        }

        return {
            set: function(id, active) {
                state[id] = Boolean(active);

                showOrHideMessage();
            }
        }
    }

	$(document).ready(function(){
		var addToCartButtons = [];

        $('[data-component="add-to-cart"]').each(function (index, element) {
            $element = $(element);
            if ($element.attr('data-add-to-cart-type') == 'icon') {
                addToCartButtons.push(new AddToCartButton($element, 'icon'));
            } else {
                addToCartButtons.push(new AddToCartButton($element, 'text'));
            }
        });

        var priceMessage = new PriceMessageState();
        var quantityInputs = [];

        $('[data-component="quantity"]').each(function(index, element) {
            $element = $(element);
            quantityInputs.push(new QuantityInput($element, priceMessage));
        })

        // update the cart dropdown markup
        $(document.body).on(
            'removed_from_cart',
            function(e, fragments) {
                var fragment = fragments['div.widget_shopping_cart_content'];
                $('.cart-dropdown').html($(fragment).find('.mini-cart-wrapper'));
            }
        );

        // update cart counter
        $(document.body).on(
            'removed_from_cart',
            function(e, fragments) {
                var fragment = fragments['menu_cart_count'];
                $('#menu-cart .cart-count').replaceWith(fragment);
            }
        );

        $(document.body).on('click', '#menu-cart', function(e) {
            e.preventDefault();
            $('body').addClass('cart-open');
        });

        $(document.body).on('click', '[data-action="close-cart"]', function (e) {
            e.preventDefault();
            $('body').removeClass('cart-open');
        });
	});

})(jQuery);